<script>
import config from '@/config';
import TariffsSelectorComponent from '@Tariffs/components/NewTariffSelector/TariffsSelectorComponent';

export default {
  name: 'TariffsTab',

  components: {
    TariffsSelectorComponent,
  },

  created() {
    this.appName = config.data.appName;
  },
};
</script>

<template>
  <div
    data-test-id="personal-profile-tariffs-tab"
    class="container-fluid my-5"
  >
    <h2 class="text-center mb-5">
      {{ $t('personal_profile.tariff.new_title', { operator: appName }) }}
    </h2>

    <TariffsSelectorComponent />
  </div>
</template>

