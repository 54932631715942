<template>
  <div
    data-test-id="insurances-tab"
    class="InsurancesTab container mt-5 mb-4"
  >
    <InsurancesSelector />
  </div>
</template>

<script>
import InsurancesSelector from '@/vue/components/organism/Insurances/InsurancesSelector/InsurancesSelector';

import { isActivatedUser } from '@/stores/User/UserData/UserDataMapper';

import PersonalProfileRoutesNames from '../../router/routes-names';

export default {

  components: {
    InsurancesSelector,
  },

  computed: {
    isActivatedUser,
  },

  created() {
    if (!this.isActivatedUser) {
      this.$router.push({ name: PersonalProfileRoutesNames.personalProfile });
    }
  },
};
</script>

<style lang="scss">
  .InsurancesTab {
    .FeedbackModalComponent__image-wrapper {
      padding-bottom: 0;

      .FeedbackModalComponent__image {
        position: relative !important;
      }
    }
  }
</style>
