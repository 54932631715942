import { useTranslations } from '@/composable/App/Translations';

import InsurancesTab from '../tabs/Insurances/InsurancesTab';
import InvoicesTab from '../tabs/Invoices/InvoicesTab';
import PromotionsTab from '../tabs/Promotions/PromotionsTab';
import TariffsTab from '../tabs/Tariffs/TariffsTab';

import routes from '../router/routes-names';

const { $t } = useTranslations();

export const computePersonalProfileTabs = ({ isActivatedUser }) => [
  {
    id: 'invoices',
    label: {
      title: $t('personal_profile.invoice.tab'),
    },
    content: [
      {
        component: InvoicesTab,
      },
    ],
    url: routes.invoices,
    isVisible: true,
  },
  {
    id: 'tariffs',
    label: {
      title: $t('personal_profile.tariff.tab'),
    },
    content: [
      {
        component: TariffsTab,
      },
    ],
    url: routes.tariffs,
    isVisible: true,
  },
  {
    id: 'insurances',
    label: {
      title: $t('personal_profile.insurance.tab'),
    },
    content: [
      {
        component: InsurancesTab,
      },
    ],
    url: routes.insurances,
    isVisible: isActivatedUser,
  },
  {
    id: 'promotions',
    label: {
      title: $t('personal_profile.promotion.tab'),
    },
    content: [
      {
        component: PromotionsTab,
      },
    ],
    url: routes.promotions,
    isVisible: true,
  },
];
