import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import snakeCase from 'lodash/snakeCase';
import { useTranslations } from '@/composable/App/Translations';

const { $t } = useTranslations();

export const useInsurance = () => {
  const getTranslatedDescriptions = (operatorName, insurances) => map(insurances, insurance => {
    if (isEmpty(operatorName)) {
      return insurance;
    }

    const snakeCaseOperatorName = snakeCase(operatorName);
    const translatedPartOne = $t(`InsuranceOptions.${insurance.internal_name}.${snakeCaseOperatorName}.dot1`);
    const translatedPartTwo = $t(`InsuranceOptions.${insurance.internal_name}.${snakeCaseOperatorName}.dot2`);
    const translatedDescription = `${translatedPartOne}\n\n\n${translatedPartTwo}`;

    return {
      ...insurance,
      description: translatedDescription,
    };
  });

  return {
    getTranslatedDescriptions,
  };
};
