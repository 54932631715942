<template>
  <Modal
    classes="phSuccessModal"
    not-close
  >
    <template slot="header" />

    <template slot="body">
      <ui-icon
        :icon="ICONS.thumbUp"
        :size="ICONS_SIZES.huge"
        :color="COLORS.secondary"
      />
      <div class="phSuccessModal_body_text emobg-font-huge">
        {{ $t('personal_profile.insurance.renew_cancelled') }}
      </div>
    </template>

    <template slot="footer">
      <ui-button
        v-bind="fetchButtonSpecs()"
        :size="SIZES.large"
        data-test-id="cancel_auto_renew_success_modal-button"
        @clickbutton="onClickClose"
      >
        {{ $t('modal.common.ok') }}
      </ui-button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/templates/Modal/Modal';
import ModalMixin from '@/mixins/Modal';
import { useTheme } from '@/composable/Theme/useTheme';

export default {

  components: {
    Modal,
  },

  mixins: [
    ModalMixin,
  ],

  setup() {
    const { fetchButtonSpecs } = useTheme();
    return { fetchButtonSpecs };
  },

  methods: {
    onClickClose() {
      this.$emit('close-modal');
      this.closeModalComponent();
    },
  },
};

</script>
