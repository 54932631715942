<template>
  <div
    data-test-id="promotions-tab"
    class="container mt-5 mb-4"
  >
    <h2 class="mb-3">
      {{ $t('personal_profile.promotion.title') }}
    </h2>
    <PromotionsViewer />
  </div>
</template>

<script>
import PromotionsViewer from '@/vue/components/organism/Promotions/PromotionsViewer/PromotionsViewer';

export default {
  name: 'PromotionsTab',
  components: {
    PromotionsViewer,
  },
};
</script>
