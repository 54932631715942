import isArray from 'lodash/isArray';
import includes from 'lodash/includes';
import isObject from 'lodash/isObject';

import { INSURANCE_TYPES } from '@/constants/insuranceTypes.const';

/**
 * Get the internal_name of the insurance
 * @param {object|string} insurance
 * @return {string}
 */
const getInternalName = (insurance = '') => (isObject(insurance) ? insurance.internal_name : insurance);

const isInsuranceType = (insurance = '', insuranceTypes = []) => {
  const internalName = getInternalName(insurance);
  const types = isArray(insuranceTypes) ? insuranceTypes : [insuranceTypes];

  return includes(types, internalName);
};

/**
 * Return if insurance is a basic insurance
 * @param {object|string} insurance
 * @return {boolean}
 */
export const isBasicInsurance = (insurance = '') => isInsuranceType(insurance, INSURANCE_TYPES.basic);

/**
 * Return if insurance is a carsharing insurance
 * @param {object|string} insurance
 * @return {boolean}
 */
export const isCsInsurance = (insurance = '') => isInsuranceType(insurance, INSURANCE_TYPES.fullCarsharing);

/**
 * Return if insurance is a long distance insurance
 * @param {object|string} insurance
 * @return {boolean}
 */
export const isLdInsurance = (insurance = '') => isInsuranceType(insurance, INSURANCE_TYPES.longDistance);

/**
 * Return if insurance is a carsharing or long distance insurance
 * @param {object|string} insurance
 * @return {boolean}
 */
export const isFullInsurance = (insurance = '') => isInsuranceType(
  insurance,
  [
    INSURANCE_TYPES.fullCarsharing,
    INSURANCE_TYPES.longDistance,
  ],
);

/**
 * Return if insurance is an annual insurance
 * @param {object|string} insurance
 * @return {boolean}
 */
export const isAnnualInsurance = (insurance = '') => isInsuranceType(insurance, INSURANCE_TYPES.annualCarsharing);
