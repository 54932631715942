<template>
  <div
    data-test-id="invoices-tab"
    class="container my-4"
  >
    <h2 class="pb-3">
      {{ $t("personal_profile.invoice.title") }}
    </h2>

    <InvoicesTable />
  </div>
</template>

<script>
import InvoicesTable from '@/vue/components/organism/Invoices/InvoicesTable';

export default {
  name: 'InvoicesTab',

  components: {
    InvoicesTable,
  },
};
</script>
