<template>
  <MainLayoutComponent data-test-id="personal_profile-view">
    <NavBar slot="header" />

    <div class="GenericTabs GenericTabs--Personal">
      <div class="w-100">
        <MuiTabs
          :active="currentTabIndex"
          :tabs="visibleTabs"
          class="TabsContent pt-5"
          @active-tab="goTo"
        />
      </div>
    </div>

    <PaymentMethodsHubListener
      @on:payment-authorised="onPaymentAuthorised"
      @on:payment-refused="onPaymentRefused"
    />
  </MainLayoutComponent>
</template>
<script>
import { computed, ref } from 'vue';

import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';

import { MuiTabs } from '@emobg/vue-base';
import { navigationErrorHandler } from '@emobg/web-utils';

import MainLayoutComponent from '@/layouts/MainLayout/MainLayoutComponent';
import NavBar from '@Shared/components/Navbar/Navbar';

import { setLoaderStatus } from '@Shared/store/Loader/LoaderMapper';
import { isActivatedUser } from '@/stores/User/UserData/UserDataMapper';
import {
  isBusinessProfileActive,
  personalProfile,
  setCurrentProfile,
} from '@/stores/User/Profile/ProfileMapper';

import {
  checkRedirectFlowOnRouteEnter,
  checkUrlQueryOnPageLeave,
  removeCommonStorageOnRouterLeave,
} from '@Shared/Payments/PaymentMethodsHub/composables/responseUtils';

import BookingRoutesNames from '@Bookings/router/routes-names';

import PaymentMethodsHubListener from '@Shared/Payments/PaymentMethodsHub/PaymentMethodsHubListener.vue';

import BusinessProfileRouteNames from '../Business/router/routes-names';

import { computePersonalProfileTabs } from './helpers/personalProfileHelper';

const routes = {
  business: BusinessProfileRouteNames,
  booking: BookingRoutesNames,
};

export default {
  name: 'PersonalProfileView',

  components: {
    MuiTabs,
    MainLayoutComponent,
    NavBar,

    PaymentMethodsHubListener,
  },

  provide() {
    return {
      paymentSuccess: computed(() => this.paymentSuccessData),
    };
  },

  beforeRouteLeave(_to, _from, next) {
    removeCommonStorageOnRouterLeave();

    next();
  },

  beforeRouteEnter(to, from, next) {
    checkRedirectFlowOnRouteEnter(from, to);

    next();
  },

  inheritAttrs: false,

  setup() {
    const isPaymentSuccess = ref(false);
    const providePaymentResponse = ref(null);

    const paymentSuccessData = ref({
      isValid: false,
      response: null,
    });

    return {
      isPaymentSuccess,
      providePaymentResponse,
      paymentSuccessData,
    };
  },

  computed: {
    isActivatedUser,
    personalProfile,
    isBusinessProfileActive,

    currentTabIndex() {
      return findIndex(this.visibleTabs, { url: this.$route.name });
    },
  },

  watch: {
    isBusinessProfileActive(isActive) {
      if (isActive) {
        this.goTo(routes.booking.home);
      }
    },
  },

  created() {
    if (!this.personalProfile) {
      this.$router.push({
        name: routes.booking.home,
      }).catch(navigationErrorHandler);
      return;
    }

    this.setLoaderStatus(false);

    if (this.isBusinessProfileActive) {
      this.setCurrentProfile(this.personalProfile);
    }

    this.TABS = computePersonalProfileTabs({ isActivatedUser: this.isActivatedUser });
    this.visibleTabs = filter(this.TABS, 'isVisible');
  },

  mounted() {
    checkUrlQueryOnPageLeave();
  },

  methods: {
    setLoaderStatus,
    setCurrentProfile,

    goTo(routeName) {
      this.$router.push({ name: routeName }).catch(navigationErrorHandler);
    },

    onPaymentAuthorised(response) {
      this.isPaymentSuccess = true;
      this.providePaymentResponse = response;

      this.paymentSuccessData = {
        isValid: true,
        response,
      };
    },

    onPaymentRefused(response) {
      this.isPaymentSuccess = false;
      this.providePaymentResponse = response;

      this.paymentSuccessData = {
        isValid: false,
        response,
      };
    },
  },
};
</script>
