<script>
import get from 'lodash/get';
import reject from 'lodash/reject';

// Components
import InsuranceCard from '@/vue/components/organism/Insurances/InsuraceCard/InsuranceCard';

// Mixins
import EventHandlerMixin from '@/mixins/EventHandler';

// Constants
import { SEGMENT_EVENTS } from '@/vue/constants';

import { getCurrentCSOperator } from '@/stores/CSOperator/CSOperatorMapper';
import {
  deleteAutorenewInsurance, getInsurances, insurances, postNewInsurance,
} from '@/stores/Insurances/InsurancesMapper';

import { userUuid } from '@/stores/User/UserData/UserDataMapper';
import { useInsurance } from '@/composable/Insurance/insurance';
import { useSegment } from '@/composable/Segment/segment';

import { isLdInsurance } from '@/helpers/booking/insurancesHelpers';

export default {
  components: {
    InsuranceCard,
  },
  mixins: [
    EventHandlerMixin,
  ],
  setup() {
    const { getTranslatedDescriptions } = useInsurance();
    const { trackSegment } = useSegment();

    return { getTranslatedDescriptions, trackSegment };
  },
  computed: {
    userUuid,
    insurances,
    getCurrentCSOperator,

    insuranceList() {
      const insuranceNoLongDistance = reject(this.insurances, this.isLdInsurance);
      const operatorName = get(this, 'getCurrentCSOperator.name');

      return this.getTranslatedDescriptions(operatorName, insuranceNoLongDistance);
    },
  },
  created() {
    this.getInsurances({ user_uuid: this.userUuid });
  },
  mounted() {
    this.eventHandler.$on(this.events.CONFIRM_INSURANCE_BUY, active => {
      this.insurances.map(item => ({
        ...item,
        isActive: item.type === active,
      }));
    });
    this.trackSegment({
      name: SEGMENT_EVENTS.INSURANCE_INFO,
    });
  },
  methods: {
    getInsurances,
    deleteAutorenewInsurance,
    postNewInsurance,
    isLdInsurance,
  },
};
</script>
<template>
  <div
    class="phInsurancesSelector"
    data-test-id="insurance-selector"
  >
    <div class="d-flex flex-wrap">
      <div
        v-for="insurance in insuranceList"
        :key="insurance.uuid"
        class="d-flex col"
      >
        <InsuranceCard :insurance="insurance" />
      </div>
    </div>
  </div>
</template>
