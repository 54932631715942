import { mapActions, mapGetters, mapState } from 'vuex';
import {
  ACTIONS,
  GETTERS,
  NAMESPACE,
} from './PromotionsModule';

export const {
  activePromotions,
  balance,
  codeError,
  getCheckPromotion,
  getUserPromotions,
  isLoaded,
  isLoading,
  pastPromotions,
  postCodeToUser,
} = {
  ...mapActions(NAMESPACE, {
    getCheckPromotion: ACTIONS.getCheckPromotion,
    getUserPromotions: ACTIONS.getUserPromotions,
    postCodeToUser: ACTIONS.postCodeToUser,
  }),

  ...mapGetters(NAMESPACE, {
    activePromotions: GETTERS.activePromotions,
    pastPromotions: GETTERS.pastPromotions,
    balance: GETTERS.balance,
  }),

  ...mapState(NAMESPACE, {
    isLoaded: state => state.code.STATUS.LOADED,
    isLoading: state => state.code.STATUS.LOADING,
    codeError: state => state.code.error,
  }),
};
