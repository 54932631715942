<template>
  <div
    data-test-id="promotion-code-view"
    class="PromotionsViewer"
  >
    <div class="PromotionsViewer__header row emobg-body-m-regular">
      <div class="col-6">
        <p class="emobg-body-m-regular">
          {{ $t('personal_profile.promotion.description_1') }}
          <span class="emobg-body-m-medium">
            {{ $t('personal_profile.promotion.description_2') }}
          </span>
        </p>
        <p class="emobg-body-m-regular mb-3">
          {{ $t('personal_profile.promotion.description_3') }}
        </p>
        <div class="PromotionsViewer__header__inputWrapper">
          <ui-text-input
            :value="code"
            data-test-id="code-input"
            name="code-input"
            :placeholder="$t('common.enter_promotion_code')"
            :size="SIZES.large"
            class="d-block mb-3"
            @changevalue="event => code = event.detail"
          />
          <ui-button
            v-bind="fetchButtonSpecs()"
            :size="SIZES.large"
            :disabled="!code"
            :loading="isLoading"
            data-test-id="code-submit-button"
            @clickbutton="addPromotionToUser"
          >
            {{ $t('personal_profile.action.submit') }}
          </ui-button>
        </div>
      </div>
      <div class="col-6">
        <div class="PromotionsViewer__header__balance">
          <div class="PromotionsViewer__header__balance__title emobg-font-small emobg-color-ink">
            {{ $t('personal_profile.promotion.balance') }}
          </div>
          <div
            class="PromotionsViewer__header__balance__result emobg-font-x-large"
            data-test-id="balance"
          >
            {{ balance }}
            <span class="PromotionsViewer__header__balance__result__info emobg-font-medium emobg-color-ink-light">
              {{ $t('personal_profile.promotion.free_hours') }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="PromotionsViewer__promotions">
      <h2 class="mb-3">
        {{ $t('personal_profile.promotion.active') }}
      </h2>
      <div
        v-if="size(activePromotions)"
        class="Ph-Table mb-3"
        data-test-id="promotions-table"
      >
        <div class="Ph-Table__header row">
          <div class="Ph-Table__row__item col col-4">
            {{ $t('personal_profile.promotion.promo') }}
          </div>
          <div class="Ph-Table__row__item col col-2">
            {{ $t('personal_profile.promotion.status') }}
          </div>
          <div class="Ph-Table__row__item col col-2 px-0">
            {{ $t('personal_profile.promotion.expire') }}
            <ui-icon
              :icon="ICONS.arrowDown"
              :size="ICONS_SIZES.xSmall"
            />
          </div>
          <div class="Ph-Table__row__item col col-2">
            {{ $t('personal_profile.promotion.amount') }}
          </div>
          <div class="Ph-Table__row__item col col-2">
            {{ $t('personal_profile.promotion.remaining') }}
          </div>
        </div>
        <div
          v-for="(activePromotion, index) in activePromotions"
          :key="index"
          class="Ph-Table__row row"
        >
          <div
            class="Ph-Table__row__item col col-4"
            :class="{'Ph-Table__row__item--pending--grey': activePromotion.statusCode === PROMOTIONS.pending }"
          >
            {{ activePromotion.externalName }}
          </div>
          <div
            class="Ph-Table__row__item col col-2"
            :class="{
              'Ph-Table__row__item--active': activePromotion.statusCode === PROMOTIONS.active,
              'Ph-Table__row__item--pending': activePromotion.statusCode === PROMOTIONS.pending
            }"
          >
            {{ activePromotion.status }}
          </div>
          <div
            class="Ph-Table__row__item col col-2"
            :class="{'Ph-Table__row__item--pending--grey': PROMOTIONS.pending }"
          >
            {{ formatDate(activePromotion.expirationDate, PROMOTION_DATE_FORMAT) }}
          </div>
          <div
            class="Ph-Table__row__item col col-2"
            :class="{'Ph-Table__row__item--pending--grey': activePromotion.statusCode === PROMOTIONS.pending}"
          >
            {{ activePromotion.originalSaldo }}
          </div>
          <div
            class="Ph-Table__row__item col col-2"
            :class="{'Ph-Table__row__item--pending--grey': activePromotion.statusCode === PROMOTIONS.pending}"
          >
            {{ activePromotion.availableSaldo }}
          </div>
        </div>
      </div>
      <div
        v-if="!size(activePromotions)"
        class="mb-3"
      >
        <EmptyStateComponent
          :icon="ICONS.credits"
          :title="$t('personal_profile.promotion.no_promo')"
          :description="$t('personal_profile.promotion.no_promo_description')"
          class="emobg-background-color-ground-lightest"
        />
      </div>

      <p class="PromotionsViewer__promotions__warning emobg-body-s-regular emobg-color-ink-light">
        {{ $t('personal_profile.promotion.description_4', { operator: appName }) }}<br>
        {{ $t('personal_profile.promotion.description_5') }}
      </p>
    </div>

    <div
      class="PromotionsViewer__invitationBanner d-flex flex-column justify-content-between w-100"
      :style="{
        background: themedBanner,
      }"
    >
      <div>
        <h1 class="PromotionsViewer__invitationBanner__title emobg-color-white emobg-font-x-large mb-3">
          {{ $t('personal_profile.promotion.illustration_1') }}
        </h1>
        <p class="PromotionsViewer__invitationBanner__text emobg-color-white emobg-body-s-regular">
          {{ $t('personal_profile.promotion.illustration_2', { operator: appName }) }}
        </p>
      </div>
      <div>
        <a
          href="/invite-a-friend"
          target="_blank"
          class="PromotionsViewer__invitationBanner__button emobg-color-white emobg-color-white-hover emobg-border-radius-small"
        >
          {{ $t('personal_profile.action.invite_friend') }}
        </a>
      </div>
    </div>

    <div class="PromotionsViewer__promotions">
      <h2 class="mb-3">
        {{ $t('personal_profile.promotion.past') }}
      </h2>
      <div
        v-if="size(pastPromotions)"
        class="Ph-Table mb-3"
      >
        <div class="Ph-Table__header row">
          <div class="Ph-Table__row__item col col-4">
            {{ $t('personal_profile.promotion.name') }}
          </div>
          <div class="Ph-Table__row__item col col-2">
            {{ $t('personal_profile.promotion.status') }}
          </div>
          <div class="Ph-Table__row__item col col-2">
            {{ $t('personal_profile.promotion.expire') }}
            <ui-icon
              :icon="ICONS.arrowDown"
              :size="ICONS_SIZES.xSmall"
            />
          </div>
          <div class="Ph-Table__row__item col col-2">
            {{ $t('personal_profile.promotion.amount') }}
          </div>
          <div class="Ph-Table__row__item col col-2">
            {{ $t('personal_profile.promotion.remaining') }}
          </div>
        </div>
        <div
          v-for="(promotion, promotionIndex) in pastPromotions"
          :key="promotionIndex"
          class="Ph-Table__row row"
        >
          <div class="Ph-Table__row__item col col-4">
            {{ promotion.externalName }}
          </div>
          <div
            class="Ph-Table__row__item col col-2"
            :class="{'Ph-Table__row__item--expired': promotion.statusCode === PROMOTIONS.expired}"
          >
            {{ promotion.status }}
          </div>
          <div
            class="Ph-Table__row__item col col-2"
            :class="{'Ph-Table__row__item--expired--red': promotion.statusCode === PROMOTIONS.expired}"
          >
            {{ formatDate(promotion.expirationDate, PROMOTION_DATE_FORMAT) }}
          </div>
          <div class="Ph-Table__row__item col col-2">
            {{ promotion.originalSaldo }}
          </div>
          <div class="Ph-Table__row__item col col-2">
            {{ promotion.availableSaldo }}
          </div>
        </div>
      </div>
      <div v-if="!size(pastPromotions)">
        <EmptyStateComponent
          :icon="ICONS.credits"
          :title="$t('personal_profile.promotion.no_past_promo')"
          :description="$t('personal_profile.promotion.no_past_promo_description')"
          class="emobg-background-color-ground-lightest"
        />
      </div>
    </div>
  </div>
</template>
<script>
import size from 'lodash/size';
import get from 'lodash/get';

import { formatDate } from '@emobg/web-utils';

import config from '@/config';
import EmptyStateComponent from '@/components/EmptyState/EmptyStateComponent';
import SegmentMixin from '@/mixins/Segment';
import { SEGMENT_EVENTS } from '@/vue/constants';

import { userData } from '@/stores/User/UserData/UserDataMapper';
import {
  activePromotions,
  balance,
  codeError,
  getUserPromotions,
  isLoaded,
  isLoading,
  pastPromotions,
  postCodeToUser,
} from '@/stores/User/Promotions/PromotionsMapper';

import { PROMOTIONS } from '@/constants/promotions.const';
// TODO: If we need other image depending of the flavor, use ImportDynamicAssets
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { ASSETS_CATEGORY, PICTURES_KEYNAMES } from '@/constants/assetsRepository';
import { getThemedAssetByCategoryAndKey } from '@/utils/assetsRepository';
import { useTheme } from '@/composable/Theme/useTheme';
import bannerImage from './banner.jpg';

const PROMOTION_DATE_FORMAT = 'DD MMM YYYY';

export default {
  name: 'PromotionsViewer',
  components: {
    EmptyStateComponent,
  },
  mixins: [
    SegmentMixin,
  ],
  setup() {
    const { notifyError, notifySuccess } = useNotifications();
    const { fetchButtonSpecs } = useTheme();
    return {
      notifyError,
      notifySuccess,
      fetchButtonSpecs,
    };
  },
  data() {
    return {
      code: '',
    };
  },
  computed: {
    activePromotions,
    balance,
    codeError,
    isLoaded,
    isLoading,
    pastPromotions,
    userData,
    themedBanner() {
      return `url('${getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.PICTURES, PICTURES_KEYNAMES.PROMOTIONS_BANNER)}') no-repeat center`;
    },
  },
  watch: {
    codeError(newError) {
      if (size(newError)) {
        // Depending of the promo code that user introduces, backend can throw a CORS error
        // the way to detect if it is a backend error message or not is checking the `key` property
        const text = newError.key
          ? newError.message
          : this.$t('personal_profile.promotion.invalid_promo_code');

        this.notifyError({
          text,
          textAction: this.$t('modal.common.ok'),
        });
      }
    },
  },
  created() {
    this.bannerImage = bannerImage;
    this.appName = config.data.appName;
    this.PROMOTIONS = PROMOTIONS;
    this.PROMOTION_DATE_FORMAT = PROMOTION_DATE_FORMAT;
    this.getUserPromotions(get(this.userData, 'uuid'));
  },
  mounted() {
    this.trackSegment({
      name: SEGMENT_EVENTS.PROMOTIONS.title,
    });
  },
  methods: {
    get,
    size,
    formatDate,
    getUserPromotions,
    postCodeToUser,

    async addPromotionToUser() {
      await this.postCodeToUser({
        userUuid: get(this.userData, 'uuid'),
        code: this.code,
      });

      if (this.isLoaded) {
        this.trackSegment({
          name: SEGMENT_EVENTS.PROMOTIONS.codeAdded,
          data: {
            promo_code: this.code,
          },
        });

        this.trackSegment({
          name: SEGMENT_EVENTS.PROMOTIONS.addCode,
        });

        this.code = '';

        this.notifySuccess({
          text: this.$t('modal.promotions.success.text'),
        });
      }
    },
  },
};
</script>
